<template>
  <nav class="navbar navbar-expand-md shadow-sm no-print">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'Dashboard' }">
        <img src="@/assets/images/Logo.svg" alt="Logo" width="126" />
      </router-link>
      <div class="collapse navbar-collapse justify-content-center">
        <ul class="navbar-nav" v-if="userInfo">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Dashboard' }">{{
              $t('__dashboard')
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="userInfo.isMultiAgency !== true ? { name: 'Report' } : { name: 'SearchReportResident' }">{{
              $t('__report')
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'DeviceManagement' }">{{
              $t('__deviceManagement')
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="d-flex flex-row">
        <div
          class="tts-setting"
          :class="viewport > breakpoints.sm ? 'dropdown' : ''"
        >
          <button
            class="btn btn-link navbar-icon nav-link"
            type="button"
            ref="ttsSettingButton"
            @click="clickTtsButton"
          >
            <i class="bi bi-volume-up-fill"></i>
          </button>
          <div
            v-if="viewport > breakpoints.sm"
            class="dropdown-menu dropdown-menu-sm-end"
            style="width: 300px; visibility: initial"
          >
            <form class="px-4 py-3" @submit.prevent="saveTtsSettingFrom">
              <label class="form-label fw-medium">{{
                $t('__ttsModeSetting')
              }}</label>
              <div class="d-flex align-items-center mb-3">
                <div class="form-check form-switch">
                  <label class="form-check-label">
                    {{ $t('__ttsMode') }}
                  </label>
                  <input class="form-check-input rounded-pill"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    v-model="tts_setting.ttsMode">
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <!-- TTS選擇語音跟語言 -->
                <select
                  v-model="tts_setting.ttsVoice"
                  class="form-select"
                  id="tts_voice_option"
                >
                  <option value=''>N/A</option>
                  <option
                    v-for="voice in tts_voices"
                    :key="voice.name + voice.lang"
                    :value="voice.name + ':' + voice.lang"
                  >
                    {{ voice.name + '/' + voice.lang }}
                  </option>
                </select>
              </div>
              <div class="row g-2">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-gray rounded-pill w-100"
                    @click="closeTtsSetting"
                  >
                    {{ $t('__cancel') }}
                  </button>
                </div>
                <div class="col">
                  <button
                    type="submit"
                    class="btn btn-primary rounded-pill w-100"
                  >
                    {{ $t('__save') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            v-if="viewport <= breakpoints.sm"
            class="offcanvas offcanvas-top"
            tabindex="-1"
            ref="ttsSettingOffcanvas"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title fw-medium" id="offcanvasExampleLabel">
                {{ $t('__ttsModeSetting') }}
              </h5>
            </div>
            <div class="offcanvas-body">
              <form @submit.prevent="saveTtsSettingFrom">
                <div class="d-flex align-items-center mb-3">
                  <div class="form-check form-switch">
                    <label class="form-check-label">
                      {{ $t('__ttsMode') }}
                    </label>
                    <input class="form-check-input rounded-pill"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      v-model="tts_setting.ttsMode">
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <!-- TTS 選擇語音跟語言 -->
                  <select
                    v-model="tts_setting.ttsVoice"
                    class="form-select"
                    id="tts_voice_option"
                  >
                    <option value=''>N/A</option>
                    <option
                      v-for="voice in tts_voices"
                      :key="voice.name + voice.lang"
                      :value="voice.name + ':' + voice.lang"
                    >
                      {{ voice.name + '/' + voice.lang }}
                    </option>
                  </select>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100 rounded-pill"
                >
                  {{ $t('__save') }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div
          v-show="userInfo && userInfo.isMultiAgency !== true"
          class="sleeptime-setting"
          :class="viewport > breakpoints.sm ? 'dropdown' : ''"
        >
          <button
            class="btn btn-link navbar-icon nav-link"
            type="button"
            ref="sleeptimesettingButton"
            @click="clickSleepingButton"
          >
            <i class="bi bi-moon-fill"></i>
          </button>
          <div
            v-if="viewport > breakpoints.sm"
            class="dropdown-menu dropdown-menu-sm-end"
            style="width: 300px; visibility: initial"
          >
            <form class="px-4 py-3" @submit.prevent="submitForm">
              <label class="form-label fw-medium">{{
                $t('__sleepTimeSetting')
              }}</label>
              <div class="d-flex align-items-center mb-3">
                <VueCtkDateTimePicker
                  :label="$t('__startTime')"
                  v-model="sleeping_time.start_at"
                  only-time
                  format="HH:mm"
                  formatted="HH:mm"
                  right
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  id="sleeping_time_start_at"
                  :disabled="!hasStatistics"
                />
                <span class="px-1">-</span>
                <VueCtkDateTimePicker
                  :label="$t('__entTime')"
                  v-model="sleeping_time.end_at"
                  only-time
                  format="HH:mm"
                  formatted="HH:mm"
                  right
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  id="sleeping_time_end_at"
                  :disabled="!hasStatistics"
                />
              </div>
              <div class="text-danger text-center mb-3" v-if="is_invalid">
                {{ feedback_message }}
              </div>
              <div class="row g-2">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-gray rounded-pill w-100"
                    @click="closeSleeptime"
                  >
                    {{ hasStatistics ? $t('__cancel') : $t('__closeWindow') }}
                  </button>
                </div>
                <div class="col" v-if="hasStatistics">
                  <button
                    type="submit"
                    class="btn btn-primary rounded-pill w-100"
                  >
                    {{ $t('__save') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            v-if="viewport <= breakpoints.sm"
            class="offcanvas offcanvas-top"
            tabindex="-1"
            ref="sleeptimeSettingOffcanvas"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title fw-medium" id="offcanvasExampleLabel">
                {{ $t('__sleepTimeSetting') }}
              </h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <form @submit.prevent="submitForm">
                <div class="d-flex align-items-center mb-3">
                  <VueCtkDateTimePicker
                    :label="$t('__startTime')"
                    v-model="sleeping_time.start_at"
                    only-time
                    format="HH:mm"
                    formatted="HH:mm"
                    right
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    id="mobile_sleeping_time_start_at"
                    :disabled="!hasStatistics"
                  />
                  <span class="px-1">-</span>
                  <VueCtkDateTimePicker
                    :label="$t('__entTime')"
                    v-model="sleeping_time.end_at"
                    only-time
                    format="HH:mm"
                    formatted="HH:mm"
                    right
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    id="mobile_sleeping_time_end_at"
                    :disabled="!hasStatistics"
                  />
                </div>
                <div class="text-danger text-center mb-3" v-if="is_invalid">
                  {{ feedback_message }}
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100 rounded-pill"
                  v-if="hasStatistics"
                >
                  {{ $t('__save') }}
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-gray rounded-pill w-100"
                  data-bs-dismiss="offcanvas"
                >
                  {{ hasStatistics ? $t('__cancel') : $t('__closeWindow') }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          class="btn btn-link navbar-icon nav-link d-none d-md-block"
          type="button"
          @click="toggleFullWindow"
        >
          <i
            class="bi"
            :class="
              is_fullWindow
                ? 'bi-arrows-angle-contract'
                : 'bi-arrows-angle-expand'
            "
          ></i>
        </button>
        <button
          class="btn btn-link navbar-icon nav-link"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#notifyOffcanvas"
          aria-controls="notifyOffcanvas"
        >
          <i class="bi bi-bell position-relative">
            <span
              v-show="has_notify"
              class="
                position-absolute
                top-0
                start-100
                translate-middle
                badge
                border border-light
                rounded-circle
                bg-danger
                p-1
              "
              ><span class="visually-hidden">unread messages</span></span
            >
          </i>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="notifyOffcanvas">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title fw-medium" id="offcanvasExampleLabel">
              <i class="bi bi-bell position-relative"></i>
              {{ $t('__notify') }}
            </h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body p-0 bg-light">
            <ul
              v-if="withinAnHourNotify && withinAnHourNotify.length"
              class="list-group list-group-flush border-top border-bottom"
            >
              <li
                v-for="(notify, index) in withinAnHourNotify"
                :key="notify.created_at + index"
                class="list-group-item"
                :class="{
                  'list-group-item-primary': timestampFormat(
                    notify.created_at_epoch
                  )
                }"
              >
                <div
                  class="
                    d-flex d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="fs-4 pe-3">
                    <i class="bi bi-exclamation-triangle"></i>
                  </div>
                  <div class="me-auto">
                    <small class="fw-medium">{{
                      notify_type_name[notify.type]
                    }}</small>
                    <small class="fw-medium ps-2">
                      {{ getDeviceInfo(notify.resident_id).bed_number }}</small
                    >
                    <p class="mb-0">
                      <span class="fs-4 pe-2 fw-medium">{{
                        getDeviceInfo(notify.resident_id).resident.name
                      }}</span>
                      <span class="">
                        {{ notify.type === 'mqttStatus' ? notify_condition_name[notify.message] : notify_condition_name[notify.type] }}
                        <span class="px-1">{{
                          notify.type === 'leaveBed' || notify.type === 'mqttStatus'
                            ? ''
                            : notify.message
                        }}</span>
                        {{ notify_unit[notify.type] }}
                      </span>
                    </p>
                    <small>{{
                      timestampFormat(notify.created_at_epoch)
                        ? timestampFormat(notify.created_at_epoch)
                        : $getTimeZoneDate(
                            notify.created_at_epoch,
                            timezone,
                            'HH:mm:ss'
                          )
                    }}</small>
                  </div>
                </div>
              </li>
            </ul>
            <div v-else class="p-3">
              {{ $t('__notDataToNotify') }}
            </div>
          </div>
        </div>
        <div class="dropdown user-info d-none d-md-block">
          <button
            class="btn btn-link navbar-icon nav-link"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ref="userInfoDropdown"
          >
            <div>
              {{ userInfo ? userInfo.name.substr(0, 1) : 'H' }}
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-end" style="width: 260px">
            <div class="px-4 py-3 text-center" v-if="userInfo">
              <div class="fw-medium">{{ userInfo.name }}</div>
              <small class="text-dark">{{ userInfo.username }}</small>
              <div class="dropdown-divider"></div>
              <div class="fw-medium">{{ userInfo.agency.name }}</div>
              <small class="text-dark">{{
                $t('__agent') + ': ' + userInfo.agency.manager.name
              }}</small>
              <div class="dropdown-divider"></div>
              <template v-if="userInfo && userInfo.isMultiAgency !== true">
                <button
                  class="btn btn-primary rounded-pill w-100 mt-2 text-white"
                  style="background-color: #06c755"
                  @click="LINENotifyAuthorize"
                >
                  <small>{{ $t('__LINENotifyAuthorize') }}</small>
                </button>
                <small class="text-center d-block" v-if="LINEAuth && LINEAuth.length">
                  {{ $t('__LINENotifyAuthorizedQuantity', { qty: LINEAuth.length }) }}
                </small>
                <small class="text-center d-block" v-else>
                  {{ $t('__LINENotifyAuthorizeNoAccount') }}
                </small>
              </template>
              <button
                type="button"
                class="btn btn-primary rounded-pill w-100 mt-3 mb-2"
                @click="signOut"
              >
                {{ $t('__signOut') }}
              </button>
              <div class="dropdown-divider"></div>
              <div>
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__language') }}：</span>
                  {{ $t(`__${userInfo.agency.locale}`) }}
                </small>
              </div>
              <div>
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__timeZone') }}：</span>
                  {{ timezone + ' ' + timeZoneOffset }}
                </small>
              </div>
              <small class="pt-3 d-block"
                >©
                {{ new Date().getFullYear() + ' ' + $t('__HumetricsInc') }} All
                rights reserved.</small
              >
              <div class="dropdown-divider"></div>
              <div>
                <small class="text-dark">
                  {{ $t('__otherUser') }}
                </small>
              </div>
              <template v-for="user in user_list">
                <div
                  class="text-center mt-3"
                  :key="user.username"
                  v-if="userInfo.username !== user.username"
                >
                  <button
                    class="btn btn-outline-primary-user w-100"
                    @click="switchUser(user.token)"
                  >
                    {{ user.agency.name + '/' + user.username }}
                  </button>
                </div>
              </template>
              <button
                type="button"
                class="btn btn-primary rounded-pill w-100 mt-3 mb-2"
                @click="loginWithOtherUser"
              >
                {{ $t('__addOtherUser') }}
              </button>
            </div>
          </div>
        </div>
        <button
          class="navbar-toggler text-dark"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarOffcanvas"
          aria-controls="navbarOffcanvas"
        >
          <i class="bi bi-list"></i>
        </button>
        <div
          class="offcanvas offcanvas-end bg-light"
          tabindex="-1"
          id="navbarOffcanvas"
          ref="navbarOffcanvas"
        >
          <div class="offcanvas-header pb-0">
            <button
              type="button"
              class="btn-close btn-close-white text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="offcanvas-body d-flex flex-column justify-content-between"
          >
            <div class="text-white mb-5 mx-3" v-if="userInfo">
              <div class="fw-medium fs-5">
                {{ userInfo.name }}
                <small>{{ userInfo.username }}</small>
              </div>
              <div class="mt-2">{{ userInfo.agency.name }}</div>
              <small>{{
                $t('__agent') + '：' + userInfo.agency.manager.name
              }}</small>
            </div>
            <ul class="navbar-nav mb-auto mt-4">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Dashboard' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__dashboard') }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Report' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__report') }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'DeviceManagement' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__deviceManagement') }}</router-link
                >
              </li>
            </ul>
            <div class="m-3">
              <template v-if="userInfo && userInfo.isMultiAgency !== true">
                <button
                  class="btn btn-primary rounded-pill w-100 mt-3 text-white"
                  style="background-color: #06c755"
                  @click="LINENotifyAuthorize"
                >
                  <small>{{ $t('__LINENotifyAuthorize') }}</small>
                </button>
                <small
                  class="text-center d-block"
                  v-if="LINEAuth && LINEAuth.length">
                  {{ $t('__LINENotifyAuthorizedQuantity', { qty: LINEAuth.length }) }}
                </small>
                <small class="text-center d-block" v-else>
                  {{ $t('__LINENotifyAuthorizeNoAccount') }}
                </small>
              </template>
              <button
                type="button"
                class="btn btn-primary rounded-pill w-100 mt-3 mb-3"
                @click="signOut"
              >
                {{ $t('__signOut') }}
              </button>
              <div class="text-center" v-if="userInfo">
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__language') }}：</span>
                  {{ $t(`__${userInfo.agency.locale}`) }}
                </small>
              </div>
              <div class="text-center">
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__timeZone') }}：</span>
                  {{ timezone + ' ' + timeZoneOffset }}
                </small>
              </div>
              <small class="pt-3 d-block text-center"
                >©
                {{ new Date().getFullYear() + ' ' + $t('__HumetricsInc') }} All
                rights reserved.</small
              >
            </div>
            <div class="dropdown-divider"></div>
              <div>
                <small class="text-dark">
                  {{ $t('__otherUser') }}
                </small>
              </div>
              <template v-for="user in user_list">
                <div
                  class="text-center mt-3"
                  :key="user.username"
                  v-if="userInfo.username !== user.username"
                >
                  <button
                    class="btn btn-outline-primary-user w-100"
                    @click="switchUser(user.token)"
                  >
                    {{ user.agency.name + '/' + user.username }}
                  </button>
                </div>
              </template>
              <button
                type="button"
                class="btn btn-primary rounded-pill w-100 mt-3 mb-2"
                @click="loginWithOtherUser"
              >
                {{ $t('__addOtherUser') }}
              </button>
          </div>
        </div>
      </div>
    </div>
    <audio src="" id='audio_ctrl' v-show=false></audio>
    <button
      v-show=welcomed
      class="welcome"
      type="button"
      id="welcome"
      @click='initAudio()'
    >
      <span class="welcome-text text-center">{{ $t('__WelcomeMsg') }}</span>
    </button>

    <div class="dialogue-box" v-if="userInfo && userInfo.isMultiAgency !== true && isDialogueTitleVisible && this.$route.name === 'Dashboard'">
      <div class="title-bar" @click="toggleDialogue">
        <h3>{{ $t('__chats') }}</h3>
        <span v-if="newMessage">{{ $t('__chatsNewMessage') }}</span>
      </div>
      <div class="content" v-if="isDialogueVisible" @scroll="handleMessageScroll">
        <ul class="chats">
          <div v-if="!canMessageLoadMore && lastMessagePage > 1" class="loadingMore">{{ $t('__chatsNoMore') }}</div>

          <li v-for="message in messages" :key="message.id">
            <div v-if="message.sender_type === 'agency'" class="out">
              <span class="datetime">
                <template v-if="message.reads === 'read'">{{ $t('__chatsAgencyReads') }}<br /></template>
                {{ message.createdAt }}
              </span>
              <div class="message">
                <span class="body" v-if="message.type === 'image'">
                  <viewer v-if="message.data" :images="[message.data.url]">
                    <img :key="message.data.url" :src="message.data.url" />
                  </viewer>
                  <viewer v-else :images="[message.content]">
                    <img :key="message.content" :src="message.content" />
                  </viewer>
                </span>
                <span class="body" v-else>{{ message.content }}</span>
              </div>
            </div>

            <div v-if="message.sender_type !== 'agency'" class="in">
              <div class="message">
                <span class="body" v-if="message.type === 'context'">{{ message.content }}</span>
                <span class="body" v-if="message.type === 'image'">
                  <viewer v-if="message.data" :images="[message.data.url]">
                    <img :key="message.data.url" :src="message.data.url" />
                  </viewer>
                  <viewer v-else :images="[message.content]">
                    <img :key="message.content" :src="message.content" />
                  </viewer>
                </span>
              </div>
              <span class="datetime">{{ message.createdAt }}</span>
            </div>
          </li>
        </ul>

        <div class="message-input">
          <input v-model="message" type="text" @keyup.enter="sendMessage()" ref="messageInput" :disabled="sendMessageStatus" :placeholder="$t('__chatsInputPlaceHolder')" />
          <input type="file" id="fileInput" @change="onFileChange" style="display: none" />

          <button class="btn-image" @click="triggerFileInput()">
            <i class="bi bi-image"></i>
          </button>
          <button class="btn-image" @click="sendMessage()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import i18n from '@/lang/lang.js'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import { Dropdown, Offcanvas } from 'bootstrap'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { dashboard } from '@/http/api/dashboard.js'
import { line } from '@/http/api/line.js'
import audioNotify from '@/assets/notify.mp3'
import Speech from 'speak-tts'
import { EventBus } from '@/eventBus'
import { message } from '@/http/api/message'
import io from 'socket.io-client'
import Echo from 'laravel-echo'

export default {
  name: 'Navbar',
  components: { VueCtkDateTimePicker },
  data () {
    return {
      sleeping_time: {
        start_at: null,
        end_at: null
      },
      tts_setting: {
        ttsMode: false,
        ttsVoice: ''
      },
      tts_voices: null,
      tts_current_setting: {
        ttsMode: false,
        ttsVoice: ''
      },
      has_notify: false,
      is_fullWindow: false,
      is_invalid: false,
      feedback_message: null,
      bootstrap_dropdown: null,
      bootstrap_offcanvas_sleeptime: null,
      bootstrap_offcanvas_tts: null,
      bootstrap_dropdown_tts: null,
      bootstrap_offcanvas_navbar: null,
      LINEAuth: null,
      oldNotifyTime: null,
      speech: null,
      welcomed: true,
      user_list: null,
      isDialogueTitleVisible: true,
      isDialogueVisible: false,
      message: '',
      heartbeatInterval: null,
      lastMessagePage: 1,
      canMessageLoadMore: true,
      messageCurrentPage: 1,
      initialMessageLoad: true,
      messages: [],
      lastMessageId: null,
      newMessage: false,
      selectedImage: null,
      sendMessageStatus: false,
      messageEchoClient: null
    }
  },
  computed: {
    ...mapState([
      'viewport',
      'token',
      'notifyList',
      'breakpoints',
      'userInfo',
      'updateCycle',
      'hasStatistics',
      'timezone',
      'echoClient'
    ]),
    ...mapGetters([
      'sortNotify',
      'timeZoneOffset',
      'notify_type_name',
      'notify_speaker_type_name',
      'notify_condition_name',
      'notify_unit'
    ]),
    withinAnHourNotify: function () {
      const vm = this
      if (!vm.sortNotify) return ''
      var withinAnHourNotify = []
      vm.sortNotify.forEach(notify => {
        if (
          Math.floor(new Date() - new Date(notify.created_at)) <=
          1000 * 60 * 60
        ) {
          withinAnHourNotify.push(notify)
        }
      })
      return withinAnHourNotify
    }
  },
  watch: {
    userInfo () {
      const vm = this
      if (vm.userInfo.status !== 1 || vm.userInfo.agency.status !== 1) { // if agency or user disable, then directly signout
        vm.signOut()
      }
      vm.getSleepingTime()
      vm.getNotification()
      vm.initBootstrapComponents()
      vm.getLINEAuth()
      if (vm.userInfo.isMultiAgency !== true) {
        vm.messageSocket()
      }
      vm.startHeartbeat()

      window.document.title =
        i18n.t(vm.$route.meta.title) + ' | ' + i18n.t('__humetrics')
      document.documentElement.lang = i18n.locale

      if (vm.echoClient) {
        vm.echoClient.listen('NotifyEvent', function (event) {
          const m = vm.$moment.tz(event.notify.created_at, 'Asia/Taipei')
          event.notify.created_at = m.toISOString()
          vm.pushToNotifyList(event.notify)
        }).listen('UpdateConfigEvent', function (event) {
          // 當其他地方有更新裝置或住民資料時需要更新整個陣列
          vm.getNotification()
          EventBus.$emit('UpdateConfigEvent', '') // 通知dashboard更新
        })
      }
    },
    sleeping_time: {
      handler: function () {
        this.is_invalid = false
      },
      deep: true
    },
    viewport () {
      const vm = this
      vm.initBootstrapComponents()
    },
    sortNotify (data) {
      if (data.length === 0 || !data) return // 如果沒資料則不判斷
      const vm = this
      const nowTime = new Date()
      if (!vm.oldNotifyTime) {
        vm.oldNotifyTime = (new Date((new Date()).toUTCString())).getTime()
      }
      const currNotifyTime = (new Date(data[0].created_at)).getTime()
      if (vm.oldNotifyTime < currNotifyTime) {
        vm.oldNotifyTime = currNotifyTime
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__notify'),
          info: i18n.t('__notifyHasNew')
        })
        vm.Alerted()
        if (data[0].type !== 'mqttStatus' && this.tts_current_setting.ttsMode) { // 如果不是mqttStatus且開啟語音模式，則用語音通報
          if (vm.speech) {
            let msg = vm.getDeviceInfo(data[0].resident_id).bed_number.split('').join(' ') + '，' + vm.getDeviceInfo(data[0].resident_id).resident.name + '，' + vm.notify_speaker_type_name[data[0].type]
            if (this.$i18n.locale === 'zh') {
              msg = msg.replace('-', '之')
            }
            vm.speech
              .speak({
                text: msg,
                queue: true,
                listeners: {
                  onstart: () => {
                    // console.log(msg)
                  },
                  onend: () => {
                    // console.log('End utterance')
                  },
                  onresume: () => {
                    // console.log('Resume utterance')
                  },
                  onboundary: event => {
                    // console.log(
                    //   event.name +
                    //     ' boundary reached after ' +
                    //     event.elapsedTime +
                    //     ' milliseconds.'
                    // )
                  }
                }
              })
              .then(data => {
                // console.log('Success !', data)
              })
              .catch(e => {
                console.log('An error occurred :' + e)
              })
          } else {
            console.log('speaker not found')
          }
        } else {
          vm.playNotifyAudio()
        }
      }
      if (Math.floor(nowTime - new Date(data[0].created_at)) <= 1000 * 60 * 3) {
        vm.has_notify = true
      } else {
        vm.has_notify = false
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted', 'pushToNotifyList', 'getToken']),
    ...mapActions(['getNotification', 'getUserInfo', 'refreshNotifyList']),
    playNotifyAudio () {
      const audio = document.getElementById('audio_ctrl')
      audio.muted = false
      audio.play()
    },
    speechInit () {
      this.speech = new Speech()
      if (!this.speech.hasBrowserSupport()) {
        alert('Your browser does NOT support speech synthesis')
      }
      this.speech
        .init({
          volume: 1,
          lang: i18n.t('__notifyTypeSpeakerLanguage'),
          rate: 0.8,
          pitch: 1,
          splitSentences: true,
          listeners: {
            onvoiceschanged: voices => {
              console.log('Voices loaded')
              this.tts_voices = voices
              if (this.tts_current_setting.ttsVoice) {
                const v = this.tts_current_setting.ttsVoice.split(':')
                this.speech.setVoice(v[0])
                this.speech.setLanguage(v[1])
              } else {
                const zhCode = ['zh-TW', 'zh_TW', 'zh-CN']
                const jaCode = ['ja-JP', 'ja_JP']
                const enCode = ['en-US', 'en_US']
                switch (i18n.t('__notifyTypeSpeakerLanguage')) {
                  case 'ja-JP':
                    voices.every((v) => {
                      if (jaCode.includes(this.convertToBCP47(v.lang))) {
                        this.tts_current_setting.ttsVoice = v.name + ':' + v.lang
                        this.speech.setLanguage(this.convertToBCP47(v.lang))
                        this.speech.setVoice(v.name)
                        return false
                      }
                      return true
                    })
                    break
                  case 'en-US':
                    if (/Android/i.test(navigator.userAgent)) { // Android上是en_US
                      voices.every((v) => {
                        if (enCode.includes(this.convertToBCP47(v.lang))) {
                          this.tts_current_setting.ttsVoice = v.name + ':' + v.lang
                          this.speech.setLanguage(this.convertToBCP47(v.lang))
                          this.speech.setVoice(v.name)
                          return false
                        }
                        return true
                      })
                    } else {
                      voices.every((v) => { // windows and apple 使用同一個聲優
                        if (enCode.includes(this.convertToBCP47(v.lang)) && v.name.includes('Samantha')) {
                          this.tts_current_setting.ttsVoice = v.name + ':' + v.lang
                          this.speech.setLanguage(this.convertToBCP47(v.lang))
                          this.speech.setVoice(v.name)
                          return false
                        }
                        return true
                      })
                    }
                    break
                  case 'zh-TW':
                  default:
                    voices.every((v) => {
                      if (zhCode.includes(this.convertToBCP47(v.lang))) {
                        this.tts_current_setting.ttsVoice = v.name + ':' + v.lang
                        this.speech.setLanguage(this.convertToBCP47(v.lang))
                        this.speech.setVoice(v.name)
                        return false
                      }
                      return true
                    })
                    break
                }
              }
            }
          }
        })
        .then(data => {
          console.log('Speech is ready ' + i18n.t('__notifyTypeSpeakerLanguage'))
        })
        .catch(e => {
          console.log(e)
        })
      this.speech // 需要發送噤聲語音，否則手持裝置可能會無法發出聲音
        .speak({
          text: '',
          queue: true
        })
        .then(data => {
        })
        .catch(e => {
          console.log('An error occurred :' + e)
        })
    },
    initAudio () {
      // initial audio
      const audio = document.getElementById('audio_ctrl')
      audio.src = audioNotify
      audio.muted = true
      audio.play()
      // check initial tts mode
      const token = localStorage.getItem('humetrics_user_token')
      const userList = JSON.parse(localStorage.getItem('user_list') || '[]')
      for (const key in userList) {
        if (userList[key].token === token) {
          if (userList[key].ttsMode) {
            this.tts_current_setting.ttsMode = userList[key].ttsMode
          } else {
            this.tts_current_setting.ttsMode = false
          }
          if (userList[key].ttsVoice) {
            this.tts_current_setting.ttsVoice = userList[key].ttsVoice
          } else {
            this.tts_current_setting.ttsVoice = null
          }
          break
        }
      }
      // initial tts
      this.speechInit()
      this.welcomed = false
      this.$store.commit('setWelcomeVisibility', false)
    },
    switchUser (token) {
      localStorage.setItem('humetrics_user_token', token)
      this.$router.push({ name: 'Dashboard' })
      this.$router.go()
    },
    loginWithOtherUser () {
      localStorage.removeItem('humetrics_user_token')
      this.$router.push({ name: 'Login' })
      this.Loaded()
    },
    signOut () {
      // 刪除user_list快取
      var token = localStorage.getItem('humetrics_user_token')
      var userList = JSON.parse(localStorage.getItem('user_list') || '[]')

      userList = userList.filter(function (user) { // 踢掉過期的使用者資料
        return user.token !== token
      })
      localStorage.setItem('user_list', JSON.stringify(userList || '[]'))
      // 刪除當前用戶token
      localStorage.removeItem('humetrics_user_token')
      this.$router.push({ name: 'Login' })
    },
    getDeviceInfo (id) {
      const vm = this
      let deviceInfo = null
      vm.notifyList.forEach(notify => {
        if (notify.resident_id === id) {
          deviceInfo = notify
        }
      })
      return deviceInfo
    },
    saveTtsSettingFrom () {
      const token = localStorage.getItem('humetrics_user_token')
      const userList = JSON.parse(localStorage.getItem('user_list') || '[]')
      for (const key in userList) {
        if (userList[key].token === token) {
          if (this.tts_setting.ttsVoice) {
            const v = this.tts_setting.ttsVoice.split(':')
            // check BCP47 format
            v[1] = this.convertToBCP47(v[1])
            this.speech.setVoice(v[0])
            this.speech.setLanguage(v[1])
          }
          userList[key].ttsMode = this.tts_setting.ttsMode
          userList[key].ttsVoice = this.tts_setting.ttsVoice
          this.tts_current_setting.ttsMode = this.tts_setting.ttsMode
          this.tts_current_setting.ttsVoice = this.tts_setting.ttsVoice
          break
        }
      }
      localStorage.setItem('user_list', JSON.stringify(userList))

      if (this.bootstrap_dropdown_tts) this.bootstrap_dropdown_tts.hide()
      if (this.bootstrap_offcanvas_tts) this.bootstrap_offcanvas_tts.hide()
    },
    format (date, formatText) {
      return new Date(date).format(formatText)
    },
    timestampFormat (unixTime) {
      // 將時間轉換顯示方式

      const timestamp = unixTime * 1000
      const minute = 1000 * 60
      const hour = minute * 60
      const day = hour * 24
      const month = day * 30
      const year = day * 365
      const now = new Date().getTime()
      const diffValue = now - timestamp
      const yearC = diffValue / year
      const monthC = diffValue / month
      const weekC = diffValue / (7 * day)
      const dayC = diffValue / day
      const hourC = diffValue / hour
      const minC = diffValue / minute

      if (yearC >= 1) {
        return null
      } else if (monthC >= 1) {
        return null
      } else if (weekC >= 1) {
        return null
      } else if (dayC >= 1) {
        return null
      } else if (hourC >= 1) {
        return null
      } else if (minC > 3) {
        return null
      } else if (minC >= 1) {
        return parseInt(minC) + i18n.t('__minAgo') // XX分鐘內
      } else {
        return i18n.t('__justNew') // 剛剛
      }
    },
    initBootstrapComponents () {
      this.$nextTick(function () {
        const vm = this
        if (vm.bootstrap_dropdown) vm.bootstrap_dropdown.dispose()
        if (vm.bootstrap_dropdow_tts) vm.bootstrap_dropdow_tts.dispose()
        if (vm.bootstrap_offcanvas_sleeptime) {
          vm.bootstrap_offcanvas_sleeptime = null
        }
        if (vm.bootstrap_offcanvas_tts) {
          vm.bootstrap_offcanvas_tts = null
        }
        if (vm.viewport <= 576) {
          vm.bootstrap_offcanvas_sleeptime = new Offcanvas(
            vm.$refs.sleeptimeSettingOffcanvas
          )
          vm.bootstrap_offcanvas_tts = new Offcanvas(
            vm.$refs.ttsSettingOffcanvas
          )
        } else {
          vm.bootstrap_dropdown = new Dropdown(
            vm.$refs.sleeptimesettingButton,
            {
              autoClose: false
            }
          )
          vm.bootstrap_dropdown_tts = new Dropdown(
            vm.$refs.ttsSettingButton,
            {
              autoClose: false
            }
          )
        }
      })
    },
    fullWindow () {
      const docElm = document.documentElement
      if (docElm.requestFullscreen) {
        // W3C
        docElm.requestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        // FireFox
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        // Chrome
        docElm.webkitRequestFullScreen()
      } else if (docElm.msRequestFullscreen) {
        // IE11
        docElm.msRequestFullscreen()
      }
    },
    cancelFullWindow () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    toggleFullWindow () {
      const vm = this
      vm.is_fullWindow = !vm.is_fullWindow
      vm.is_fullWindow ? vm.fullWindow() : vm.cancelFullWindow()
    },
    clickSleepingButton () {
      const vm = this
      vm.$store.dispatch('getStatistics').then(() => {
        // if (vm.hasStatistics) {
        // }
      })
      if (Number(vm.viewport) === vm.breakpoints.sm) {
        vm.bootstrap_offcanvas_sleeptime.show()
      }
    },
    clickTtsButton () {
      const vm = this
      const token = localStorage.getItem('humetrics_user_token')
      const userList = JSON.parse(localStorage.getItem('user_list') || '[]')
      for (const key in userList) {
        if (userList[key].token === token) {
          if (userList[key].ttsMode) {
            vm.tts_setting.ttsMode = userList[key].ttsMode
          } else {
            vm.tts_setting.ttsMode = false
          }
          if (userList[key].ttsVoice) {
            vm.tts_setting.ttsVoice = userList[key].ttsVoice
          } else if (vm.tts_current_setting.ttsVoice) {
            vm.tts_setting.ttsVoice = vm.tts_current_setting.ttsVoice
          } else {
            vm.tts_setting.ttsVoice = null
          }
          break
        }
      }
      if (Number(vm.viewport) === vm.breakpoints.sm) {
        vm.bootstrap_offcanvas_tts.show()
      }
    },
    getSleepingTime () {
      const vm = this
      dashboard
        .getSleepingTime(vm.token)
        .then(res => {
          if (res.status <= 201) {
            const data = res.data.data
            vm.sleeping_time.start_at = data.start
            vm.sleeping_time.end_at = data.end
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
        })
        .catch(err => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
        })
    },
    checkForm () {
      const vm = this
      vm.feedback_message = i18n.t('__sleepTimeIsRequired')
      if (!vm.sleeping_time.start_at) {
        vm.is_invalid = true
      } else if (!vm.sleeping_time.end_at) {
        vm.is_invalid = true
      } else {
        vm.is_invalid = false
        vm.feedback_message = null
      }
    },
    closeSleeptime () {
      const vm = this
      if (vm.bootstrap_dropdown) vm.bootstrap_dropdown.hide()
    },
    closeTtsSetting () {
      const vm = this
      if (vm.bootstrap_dropdown_tts) vm.bootstrap_dropdown_tts.hide()
    },
    submitForm () {
      const vm = this
      vm.checkForm()
      if (vm.is_invalid) return
      if (vm.bootstrap_dropdown) vm.bootstrap_dropdown.hide()
      if (vm.bootstrap_offcanvas_sleeptime) {
        vm.bootstrap_offcanvas_sleeptime.hide()
      }
      vm.Loading()
      dashboard
        .updateSleepingTime(
          {
            start: vm.sleeping_time.start_at,
            end: vm.sleeping_time.end_at
          },
          vm.token
        )
        .then(res => {
          vm.Loaded()
          if (res.status <= 201) {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__sleepTimeUpdatedSuccessfully')
            })
            vm.Alerted()
            vm.getSleepingTime()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
        })
        .catch(error => {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
          vm.Alerted()
        })
    },
    getLINEAuth () {
      const vm = this
      line.lineAuth(vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.LINEAuth = res.data.data
        } else {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    LINENotifyAuthorize () {
      const vm = this
      vm.Loading()
      line
        .lineOauth(vm.token)
        .then(res => {
          if (res.status <= 201) {
            vm.Loaded()
            window.open(res.data.authLink)
          } else {
            vm.Loaded()
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errors.toString()
            })
            vm.Alerted()
          }
        })
        .catch(error => {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
          vm.Alerted()
        })
    },
    convertToBCP47 (lang) {
      // turn "_" into "-"
      let convertedTag = lang.replace(/_/g, '-')
      // remove "#"
      convertedTag = convertedTag.replace(/#/g, '')

      // Ensure the region code is in uppercase
      const parts = convertedTag.split('-')
      if (parts.length > 1) {
        parts[1] = parts[1].toUpperCase()
      }
      convertedTag = parts.slice(0, 2).join('-')
      return convertedTag
    },
    toggleDialogue () {
      this.isDialogueVisible = !this.isDialogueVisible

      this.messages = []
      this.lastMessageId = null
      this.lastMessagePage = 1
      this.canMessageLoadMore = true
      this.messageCurrentPage = 1
      this.initialMessageLoad = true

      if (this.isDialogueVisible) {
        this.fetchMessages()
        this.startReads()
      } else {
        clearInterval(this.readsInterval)
      }

      this.newMessage = false
      this.$nextTick(() => {
        setTimeout(() => {
          const messageContent = this.$el.querySelector('.content')
          if (messageContent) {
            messageContent.scrollTop = messageContent.scrollHeight
          }
        }, 500)
      })
    },
    messageSocket () {
      const vm = this

      vm.messageEchoClient = new Echo({
        broadcaster: 'socket.io',
        host: 'https://' + window.location.hostname,
        client: io,
        options: {
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 30000,
          transports: ['websocket', 'polling']
        }
      })

      vm.messageEchoClient.connector.socket.on('disconnect', () => {
        console.log('socket disconnect')
      })
      vm.messageEchoClient.connector.socket.on('connect', () => {
        console.log('socket connect')
      })

      vm.messageEchoClient.channel('broadcast_database_chat.' + vm.userInfo.agency.id)
        .listen('MessageSentEvent', (event) => {
          vm.messages.push(event.message)
          if (vm.isDialogueVisible === false) {
            vm.newMessage = true
          } else {
            message.markMessageAsRead('', vm.token, { roomId: vm.userInfo.agency.room_member.room_id }).then(res => {
            })
            vm.scrollMessagesToBottom()

            if (event.message.type === 'image') {
              vm.scrollToImage()
            }
          }
        })
    },
    sendMessage () {
      const vm = this

      if (vm.message === '' || vm.message === null) {
        return
      }

      vm.sendMessageStatus = true

      message.sendFromAgency({
        content: vm.message
      }, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.message = ''
          vm.sendMessageStatus = false
          vm.scrollMessagesToBottom()
          vm.$nextTick(() => {
            vm.$refs.messageInput.focus()
          })
        }
      }).catch(err => {
        vm.sendMessageStatus = true
        console.log(err)
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        vm.Alerted()
      })
    },
    scrollMessagesToBottom () {
      this.$nextTick(() => {
        const messageContent = this.$el.querySelector('.content')
        if (messageContent) {
          messageContent.scrollTop = messageContent.scrollHeight
        }
      })
    },
    startHeartbeat () {
      this.heartbeat()
      this.heartbeatInterval = setInterval(this.heartbeat, 30000)
    },
    startReads () {
      this.reads()
      this.readsInterval = setInterval(this.reads, 5000)
    },
    heartbeat () {
      const vm = this
      message.online({}, vm.token).then(res => {
      })
    },
    reads () {
      const vm = this
      message.reads({}, vm.token).then(res => {
      })
    },
    handleMessageScroll (event) {
      const wrapper = event.target
      if (this.canMessageLoadMore && this.lastMessagePage > 1 && wrapper.scrollTop === 0) {
        this.fetchMessages()
      }
    },
    fetchMessages () {
      const vm = this
      vm.getToken()

      const params = {
        page: vm.messageCurrentPage,
        per_page: 50,
        lastId: vm.lastMessageId
      }

      message.getHistoryFromAgency('', vm.token, params).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.initialMessageLoad = false

          if (res.data.data.messages.data.length === 0) {
            vm.canMessageLoadMore = false
          } else {
            vm.messages.unshift(...res.data.data.messages.data.reverse())
            vm.lastMessageId = res.data.data.messages.data[0].id
          }

          if (vm.messageCurrentPage === 1) {
            vm.scrollMessagesToBottom()
          } else {
            const wrapper = vm.$el.querySelector('.content')
            const previousScrollHeight = wrapper.scrollHeight
            const previousScrollTop = wrapper.scrollTop
            this.$nextTick(() => {
              const newScrollHeight = wrapper.scrollHeight
              wrapper.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight)
            })
          }

          this.$nextTick(() => {
            if (vm.isDialogueVisible === true) {
              vm.$refs.messageInput.focus()
            }
          })

          vm.messageCurrentPage++
          vm.lastMessagePage++
        }
      }).catch(error => {
        console.error('Error fetching messages:', error)
      })
    },
    onFileChange (e) {
      this.selectedImage = e.target.files[0]
      if (this.selectedImage) {
        this.uploadImage()
        e.target.value = ''
      }
    },
    triggerFileInput () {
      document.getElementById('fileInput').click()
    },
    uploadImage () {
      this.Loading()
      this.sendMessageStatus = true

      const formData = new FormData()
      formData.append('image', this.selectedImage, this.selectedImage.name)
      formData.append('roomId', this.userInfo.agency.room_member.room_id)
      formData.append('agencyId', this.userInfo.agency.id)
      formData.append('storeFolder', 'chats')

      message.sendImage(formData, this.token).then(res => {
        this.selectedImage = null
        this.sendMessageStatus = false
        this.Loaded()
      }).catch(err => {
        this.selectedImage = null
        this.sendMessageStatus = false
        this.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        this.Alerted()
        this.Loaded()
      })
    },
    scrollToImage () {
      this.$nextTick(() => {
        const images = this.$el.querySelectorAll('.chats img')
        let loadedImageCount = 0
        images.forEach((img) => {
          if (img.complete) {
            loadedImageCount++
            if (loadedImageCount === images.length) {
              this.scrollMessagesToBottom()
            }
          } else {
            img.onload = () => {
              loadedImageCount++
              if (loadedImageCount === images.length) {
                this.scrollMessagesToBottom()
              }
            }
          }
        })

        if (images.length === 0 || loadedImageCount === images.length) {
          this.scrollMessagesToBottom()
        }
      })
    }
  },
  created () {
    const vm = this
    vm.getUserInfo()
  },
  mounted () {
    const vm = this
    document.querySelector('body').style.cssText = ''
    vm.$refs.userInfoDropdown.addEventListener('show.bs.dropdown', function () {
      vm.isDialogueTitleVisible = false
      vm.user_list = JSON.parse(localStorage.getItem('user_list') || '[]')
      vm.getLINEAuth()
    })
    vm.$refs.userInfoDropdown.addEventListener('hide.bs.dropdown', function () {
      vm.isDialogueTitleVisible = true
    })
    vm.$refs.navbarOffcanvas.addEventListener('show.bs.offcanvas', function () {
      vm.getLINEAuth()
    })
    const timer = setInterval(() => { // 30秒更新一次notify清單
      vm.refreshNotifyList()
    }, vm.updateCycle * 30)
    vm.$once('hook:beforeDestroy', () => {
      clearInterval(this.heartbeatInterval)

      clearInterval(timer)
      // 離開頻道
      if (this.echoClient) {
        this.echoClient.stopListening('NotifyEvent').stopListening('UpdateConfigEvent')
      }

      if (vm.messageEchoClient) {
        vm.messageEchoClient.disconnect()
      }
    })
  }
}
</script>

<style lang="scss">
.navbar {
  background-color: $white;
  a,
  &-icon {
    color: $dark;
  }
  .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    &:hover,
    &:focus {
      color: $primary;
    }
    &.btn {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .navbar-collapse {
    .nav-link {
      color: rgba($dark, 0.5);
      padding-right: 2rem;
      padding-left: 2rem;
      @include media-breakpoint-down(lg) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
      &.active {
        color: $dark;
        position: relative;
        &::after {
          content: '';
          width: 100%;
          height: 0.3rem;
          background: $linear-gradient;
          position: absolute;
          top: calc(-0.5rem - 1px);
          left: 0;
        }
      }
      &:hover {
        color: $dark;
      }
    }
  }
  .user-info {
    > .btn {
      padding: 0;
      line-height: 42px;
      div {
        color: $white;
        z-index: 1;
        position: relative;
        width: 30px;
        &::after {
          content: '';
          width: 100%;
          height: 0;
          padding-top: 100%;
          background: $linear-gradient;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
    }
  }
}
#navbarOffcanvas {
  overflow: hidden;
  .offcanvas-header {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .offcanvas-body {
    overflow-x: hidden;
    position: relative;
    padding: 32px 0 0 0;
    &::before {
      content: '';
      width: 50%;
      height: 0;
      padding-top: 50%;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url('~@/assets/images/icon-white.svg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.3;
      transform: translateY(-20%);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 150%;
      height: 0;
      padding-top: 370px;
      background: $linear-gradient;
      border-radius: 0 35%;
      z-index: -1;
      transform: translate(-15%, -219px) rotate(345deg);
    }
  }
  .nav-link {
    padding: 1rem 1rem;
    color: rgba($dark, 0.7);
    font-weight: 500;
    &.active {
      color: $dark;
      background-color: $white;
      position: relative;
      &::after {
        content: '';
        width: 0.3rem;
        height: 100%;
        background: $linear-gradient;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
#notifyOffcanvas {
  .dropdown {
    .btn-link {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.welcome {
  background-image: url('~@/assets/images/welcome_icon.png');
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#fff, 0.9);
  z-index: 99999;
  &-text {
    width: 90%;
    position: absolute;
    top: 55%;
    left: 50%;
    font-size: 20px;
    font-weight: 500;
    color: $primary;
    transform: translateX(-50%);
    z-index: 99999;
  }
}

@include media-breakpoint-down(sm) {
  .dialogue-box {
    width: auto !important;
    .title-bar {
      width: 200px !important;
    }
  }
}

.dialogue-box {
  position: absolute;
  top: 100%;
  right: 0;
  width: 370px;
  background-color: #00b4d8;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  border-radius:  0 0 0 10px;
  overflow: hidden;
  z-index: 1000;
}

.dialogue-box .title-bar {
  background-color: #00b4d8;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogue-box .title-bar h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

.dialogue-box .title-bar span {
  color: red;
}

.dialogue-box .content {
  padding: 5px;
  background-color: #00b4d8;
  max-height: 500px;
  overflow-y: auto;
}

.message-input {
  padding: 10px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  border-radius: 0 0 0 5px;
}

.message-input input {
  flex-grow: 1;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid #ccc;
}

.message-input .btn-send, .message-input .btn-image {
  padding: 5px 10px;
  background: #00b4d8;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-left: 4px;
}

.chats {
  padding: 10px;
  background: white;
  min-height: 300px;
  overflow-y: auto;
}

.chats li {
  list-style: none;
  padding: 5px 0;
  margin: 0;
  font-size: 12px;
  display: flex;
  width: 100%;
}

.chats li .out,
.chats li .in {
  display: flex;
  width: 100%;
}

.chats li .out {
  margin-bottom: 5px;
  justify-content: flex-end; /* 新增，將 out 對齊到右邊 */
}

.chats li .in {
  justify-content: flex-start; /* 新增，將 in 對齊到左邊 */
}

.chats li .out .message,
.chats li .in .message {
  background: #fafafa;
  text-align: left;
  position: relative;
  border-radius: 20px 20px 8px 20px;
  margin: 0;
  display: flex;
  align-items: flex-end;
  color: var(--ion-color-white);
  padding: 15px;
  max-width: 85%;
}

.chats .message .body {
  white-space: pre-line;
  word-break: break-all;
  font-size: 16px;
}

.chats .datetime {
  align-items: end;
  display: flex;
  font-size: 10px;
  color: #999;
}

.chats .message .body img {
  width: 100%;
}

.chats li .in .message {
  text-align: left;
  border-radius: 20px 20px 20px 8px;
  color: #ffffff;
  margin-left: 0;
  background: #1BBC9B;
}

.chats .out .datetime {
  margin-right: 5px;
}

.chats .in .datetime {
  margin-left: 5px;
}

.dialogue-box .content::-webkit-scrollbar {
  width: 10px;
}

.dialogue-box .content::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
  margin: 2px 0;
}

.dialogue-box .content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.loadingMore {
  padding: 20px;
  text-align: center;
  color: #999999;
}
</style>
